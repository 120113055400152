<template>
  <div>
    <b-card title="Section FAQ">
      <b-table
        hover
        responsive
        show-empty
        empty-text="Aucune section"
        :items="items"
        :fields="fields"
        :busy="loaded"
      >
        <template #table-busy>
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="primary"
              label="Loading..."
            />
          </div>
        </template>
        <template v-slot:cell(created)="data">
          {{ data.item.created_at | moment("DD/MM/YY HH:mm") }}
        </template>
        <template v-slot:cell(updated)="data">
          {{ data.item.updated_at | moment("DD/MM/YY HH:mm") }}
        </template>
        <template v-slot:cell(action)="data">
          <span class="whitespace-no-wrap">
            <feather-icon
              icon="EditIcon"
              svg-classes="w-5 h-5 hover:text-success stroke-current"
              class="text-warning"
              @click="openModal(data.item)"
            />
          </span>
          <span class="whitespace-no-wrap">
            <feather-icon
              icon="TrashIcon"
              svg-classes="w-5 h-5 hover:text-danger stroke-current"
              class="text-danger ml-1"
              @click="openDeleteModal(data.item)"
            />
          </span>
        </template>
      </b-table>
      <div class="mt-2">
        <div class="float-left">
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
          />
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="right"
        />
      </div>
    </b-card>
    <b-modal
      v-if="showModal"
      v-model="showModal"
      centered
      hide-footer
      title="Modification"
      :no-close-on-backdrop="process"
      :hide-header-close="process"
      :busy="process"
    >
      <div>
        <b-row>
          <b-col
            cols="12"
          >
            <b-form-group label="Dénomination">
              <b-form-input
                v-model="$v.currentSection.section.$model"
                :formatter="formatter"
                :class="{ 'is-invalid': $v.currentSection.section.$error }"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div
              v-if="process"
              class="d-flex justify-content-center mt-1"
            >
              <b-spinner
                variant="primary"
                label="Loading..."
              />
            </div>
            <div class="mt-3">
              <b-button
                type="submit"
                variant="outline-primary"
                class="ml-2 float-right"
                :disabled="process"
                @click="tryToUpdate"
              >
                Modifier
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      v-if="showDeleteModal"
      v-model="showDeleteModal"
      centered
      hide-footer
      title="Suppression"
      :no-close-on-backdrop="delProcess"
      :hide-header-close="delProcess"
      :busy="delProcess"
    >
      <div>
        <div class="p-2">
          Voulez-vous supprimer la section du système ?
        </div>
        <b-row>
          <b-col cols="12">
            <div
              v-if="delProcess"
              class="d-flex justify-content-center mt-1"
            >
              <b-spinner
                variant="danger"
                label="Loading..."
              />
            </div>
            <div class="mt-3">
              <b-button
                type="submit"
                variant="outline-danger"
                class="ml-2 float-right"
                :disabled="delProcess"
                @click="tryToDelete"
              >
                Supprimer
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  data() {
    return {
      items: [],
      currentPage: 1,
      currentState: 1,
      loaded: false,
      perPage: 10,
      totalItems: 0,
      showModal: false,
      showDeleteModal: false,
      pageOptions: [5, 10, 15],
      currentSection: {
        id: null,
        section: null,
      },
      fields: [
        { key: 'section', label: 'Section' },
        { key: 'created', label: 'Ajouté' },
        { key: 'updated', label: 'Modifié' },
        { key: 'action', label: 'Action' },
      ],
    }
  },
  validations: {
    currentSection: {
      section: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters({
      success: 'updateFaqSectionSuccess',
      error: 'updateFaqSectionError',
      process: 'updateFaqSectionProcess',

      delSuccess: 'deleteFaqSectionSuccess',
      delError: 'deleteFaqSectionError',
      delProcess: 'deleteFaqSectionProcess',
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAllFaqSections()
      },
    },
    currentState() {
      this.getAllFaqSections()
    },
    delSuccess(val) {
      if (val) {
        this.closeDeleteModal()
        this.getAllFaqSections()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Suppression de la section',
            text: 'Supprimé avec succès',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    delError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Suppression de la section',
            text: 'Une erreur est survenue',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    success(val) {
      if (val) {
        this.closeModal()
        this.getAllFaqSections()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Modification de la section',
            text: 'Modifié avec succes',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Modification de la section',
            text: 'Une erreur est survenue',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['updateFaqSection', 'deleteFaqSection']),
    formatter(value) {
      return value.toUpperCase()
    },
    resetForm() {
      this.section = null
      this.$nextTick(() => {
        this.$v.$reset()
      })
    },
    openModal(item) {
      this.currentSection = {
        section: item.section,
        id: item.id,
      }
      this.showModal = true
    },
    closeModal() {
      this.currentSection = null
      this.showModal = false
    },
    openDeleteModal(item) {
      this.currentSection.id = item.id
      this.showDeleteModal = true
    },
    closeDeleteModal() {
      this.currentSection = null
      this.showDeleteModal = false
    },
    tryToUpdate() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        return
      }
      this.updateFaqSection({
        id: this.currentSection.id,
        section: this.currentSection.section,
      })
    },
    tryToDelete() {
      this.deleteFaqSection(this.currentSection.id)
    },
    async getAllFaqSections() {
      this.loaded = true
      this.items = await this.$http
        .get(`/faq-all-paged/${this.perPage}?page=${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => {
          const { data } = res
          this.loaded = false
          this.totalItems = data.meta.total
          return data.data
        })
        .catch(() => {
          this.loaded = false
          return []
        })
    },
  },
}
</script>

<style>

</style>
